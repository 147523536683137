import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import { INavQuery } from '../../../../@types'
import { PageTitles } from '../../../../vars'
import { PureNav } from './PureNav'

export interface INavProps {
  currentPageTitle: PageTitles | string
  open: boolean
  darkBg: boolean
  useHamburger: boolean
}

export function Nav(props: INavProps) {
  const data: INavQuery = useStaticQuery(graphql`
    {
      allSanityCategory(filter: { categoryName: { ne: "Cara" } }) {
        nodes {
          categoryName
          slug {
            current
          }
          icon {
            asset {
              url
            }
          }
        }
      }
    }
  `)

  return <PureNav data={data} {...props} />
}
