import React from 'react'
import styled from 'styled-components'

import imageIcon from '../../static/svg/image.svg'
import homeIcon from '../../static/svg/home-alt.svg'

import { ButtonLink, cardEnterAnim } from '../globalStyles'
import { Layout } from '../components/common/Layout'
import { mediaQueries, PageTitles, PageURLs } from '../vars'
import { rhythm } from '../utils/typography'

export default function PurchaseSuccess() {
  return (
    <Layout currentPageTitle={PageTitles.Contact} darkBg={false}>
      <Container>
        <h1>Thank you for your purchase!</h1>

        <p>
          You should receive a receipt at your specified email address along
          with status updates regarding your order.
        </p>
        <Link to={PageURLs.PhotosLarry}>
          Find more photos
          <img src={imageIcon} alt='Image icon' />
        </Link>
        <Link to={PageURLs.Home}>
          Home
          <img src={homeIcon} alt='Home icon' />
        </Link>
      </Container>
    </Layout>
  )
}

const Container = styled.section`
  animation: ${cardEnterAnim} 0.6s 0.2s backwards;
  position: relative;
  background: #ffffffdd;
  padding: ${rhythm(1)};
  border-radius: 10px;
  width: fit-content;
  margin: ${rhythm(1)} auto;

  h1 {
    font-size: 2rem;

    @media ${mediaQueries.general.handheld} {
      font-size: 1.3rem;
      line-height: 1.9rem;
      margin-bottom: ${rhythm(1)};
    }
  }
`

const Link = styled(ButtonLink)`
  color: var(--black);
  box-shadow: var(--box-shadow-light);
  background-color: #fff;
  font-weight: bold;
  width: 100%;
  padding: ${rhythm(1 / 4)};
  border-radius: 5px;
  border: 1px solid var(--primary);
  justify-content: center;
  align-items: center;
  margin: ${rhythm(1)} 0;

  img {
    height: 1.4rem;
    margin-left: 0.2rem;
  }
`
