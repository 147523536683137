import React, { ReactElement, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import searchIcon from '../../../static/svg/search.svg'

import { CSSTransition } from 'react-transition-group'
import { ISanityPhoto, TTogglePhotoPopup } from '../../@types'
import { Layout } from '../common/Layout'
import { Photo } from './Photo'
import { PhotoPopup } from './PhotoPopup'
import {
  animationDuration,
  Container,
  EmptyImages,
  Header,
  Images,
  PopupWrapper,
  SearchForm,
  SearchIcon,
  SearchInput,
  TopRow,
} from './styles'
import { CustomPrintNotification } from '../common/CustomPrintNotification'
import { PageTitles } from '../../vars'

const documentGlobal: Document | false =
  typeof document !== 'undefined' && document

interface IPhotosProps {
  photoArray: ISanityPhoto[]
  pageTitle: string
  iconURL: string
}

export function PhotosPage({ photoArray, pageTitle, iconURL }: IPhotosProps) {
  if (!documentGlobal) {
    return null
  }

  const [photoPopup, setPhotoPopup] = useState<boolean>(false)
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>(0)
  const [addToCart, setAddToCart] = useState<boolean>(false)
  const [searchFocused, setSearchFocused] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredPhotos, setFilteredPhotos] = useState<ISanityPhoto[]>(
    photoArray
  )

  function togglePhotoPopup(
    photoIndex: number,
    addToCartClicked: boolean
  ): void {
    setCurrentPhotoIndex(photoIndex)

    setPhotoPopup(!photoPopup)

    setAddToCart(addToCartClicked)
  }

  function handleSearchValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value)
  }

  function toggleSearchFocus() {
    setSearchFocused(!searchFocused)
  }

  useEffect(() => {
    if (searchValue.length < 1) {
      setFilteredPhotos(photoArray)

      return
    }

    const re = new RegExp(searchValue, 'i')

    const newFilteredPhotos = photoArray.filter(photo => {
      const keywordsMatch = photo.keywords.some(keyword => {
        return re.test(keyword)
      })

      const categoriesMatch = photo._rawCategories.some(category => {
        return re.test(category.categoryName)
      })

      const descriptionMatch = re.test(photo.desc)

      const titleMatch = re.test(photo.photoTitle)

      return keywordsMatch || categoriesMatch || descriptionMatch || titleMatch
    })

    setFilteredPhotos(newFilteredPhotos)
  }, [searchValue])

  const images: ReactElement[] = getImages(filteredPhotos, togglePhotoPopup)

  return (
    <Layout darkBg={false} currentPageTitle={pageTitle}>
      <>
        <Container>
          <TopRow>
            <Header>
              <h1>
                Photos
                <span>
                  /{' '}
                  {pageTitle === PageTitles.PhotosLarry
                    ? 'By Larry'
                    : pageTitle === PageTitles.PhotosCara
                    ? 'By Cara'
                    : pageTitle}
                </span>
                <img src={iconURL} alt='Photo icon' />
              </h1>
            </Header>

            <SearchForm searchFocused={searchFocused}>
              <SearchInput
                type='search'
                name='photoSearch'
                id='photoSearch'
                value={searchValue}
                onChange={handleSearchValueChange}
                aria-label='Photo search'
                results={5}
                autoSave='searchAutoSaveKey'
                placeholder='Search...'
                onFocus={toggleSearchFocus}
                onBlur={toggleSearchFocus}
              />
              <SearchIcon src={searchIcon} alt='Search icon' />
            </SearchForm>
          </TopRow>

          {images.length > 0 ? (
            <Images>{images}</Images>
          ) : (
            <EmptyImages>Nothing here yet!</EmptyImages>
          )}
          <CustomPrintNotification />
        </Container>

        {createPortal(
          <CSSTransition
            in={photoPopup}
            timeout={animationDuration}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <PopupWrapper>
              <PhotoPopup
                togglePhotoPopup={togglePhotoPopup}
                photoArray={filteredPhotos}
                photoIndex={currentPhotoIndex}
                addToCartInitial={addToCart}
              />
            </PopupWrapper>
          </CSSTransition>,
          documentGlobal.body
        )}
      </>
    </Layout>
  )
}

function getImages(
  photoArray: ISanityPhoto[],
  togglePhotoPopup: TTogglePhotoPopup
): ReactElement[] {
  return photoArray.map((sanityPhoto: ISanityPhoto, index) => (
    <Photo
      key={`${sanityPhoto.photoTitle}${index}`}
      photoIndex={index}
      sanityPhoto={sanityPhoto}
      togglePhotoPopup={togglePhotoPopup}
    />
  ))
}
