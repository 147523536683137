import React, { useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import cartIcon from '../../../../../static/svg/cart.svg'
import home from '../../../../../static/svg/home-alt.svg'
import photo from '../../../../../static/svg/image.svg'
import contactIcon from '../../../../../static/svg/contact-book.svg'
import personIcon from '../../../../../static/svg/user.svg'

import { INavQuery } from '../../../../@types'
import { getCurrentCart } from '../../../../functions/getCurrentCart'
import { useClickOutside } from '../../../../hooks/useClickOutside'
import {
  caraPhotosSuffix,
  caraPhotosURLPrefix,
  larryPhotosSuffix,
  larryPhotosURLPrefix,
  PageTitles,
  PageURLs,
} from '../../../../vars'
import { Dropdown } from './Dropdown'
import { INavProps } from './index'
import {
  CartIconContainer,
  Icon,
  ListItem,
  NavContainer,
  NavLink,
  Ul,
} from './styles'

interface IPureNavProps extends INavProps {
  data: INavQuery
}

export function PureNav({
  currentPageTitle,
  open,
  darkBg,
  useHamburger,
  data,
}: IPureNavProps) {
  const { nodes: categories } = data.allSanityCategory

  const [larryDropdown, setLarryDropdown] = useState(false)
  const [caraDropdown, setCaraDropdown] = useState(false)

  const larryDropdownRef = useRef<HTMLDivElement>(null)
  const caraDropdownRef = useRef<HTMLDivElement>(null)

  const [larryPhotosIsCurrentPage, setLarryPhotosIsCurrentPage] = useState(
    currentPageTitle === PageTitles.PhotosLarry
  )
  const [caraPhotosIsCurrentPage, setCaraPhotosIsCurrentPage] = useState(
    currentPageTitle === PageTitles.PhotosCara
  )

  useClickOutside(larryDropdownRef, removeLarryDropdown)
  useClickOutside(caraDropdownRef, removeCaraDropdown)

  function removeLarryDropdown() {
    if (larryDropdown) {
      setLarryDropdown(false)
    }
  }

  function removeCaraDropdown() {
    if (caraDropdown) {
      setCaraDropdown(false)
    }
  }

  function addLarryDropdown() {
    if (!larryDropdown) {
      setLarryDropdown(true)
    }
  }

  function addCaraDropdown() {
    if (!caraDropdown) {
      setCaraDropdown(true)
    }
  }

  function conditionalLinkCancel(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    if (useHamburger) {
      event.preventDefault()
    }
  }

  const cartNumber = getCartNumber()

  const larryDropdownEl = (
    <Dropdown
      categories={categories}
      currentPageTitle={currentPageTitle}
      allPageTitle={PageTitles.PhotosLarry}
      allPageURL={PageURLs.PhotosLarry}
      photosURLPrefix={larryPhotosURLPrefix}
      catSuffix={larryPhotosSuffix}
      darkBg={darkBg}
      dropdown={larryDropdown}
      dropdownRef={larryDropdownRef}
      removeDropdown={removeLarryDropdown}
      setPhotosIsCurrentPage={setLarryPhotosIsCurrentPage}
      useHamburger={useHamburger}
    />
  )

  const caraDropdownEl = (
    <Dropdown
      categories={categories}
      currentPageTitle={currentPageTitle}
      allPageTitle={PageTitles.PhotosCara}
      allPageURL={PageURLs.PhotosCara}
      photosURLPrefix={caraPhotosURLPrefix}
      catSuffix={caraPhotosSuffix}
      darkBg={darkBg}
      dropdown={caraDropdown}
      dropdownRef={caraDropdownRef}
      removeDropdown={removeCaraDropdown}
      setPhotosIsCurrentPage={setCaraPhotosIsCurrentPage}
      useHamburger={useHamburger}
    />
  )

  const showMainLinks =
    (useHamburger && !larryDropdown && !caraDropdown) || !useHamburger

  return (
    <CSSTransition
      in={open}
      timeout={{ enter: 0, exit: 500 }}
      mountOnEnter={true}
      unmountOnExit={true}
      appear={true}
    >
      <NavContainer
        darkBg={darkBg}
        useHamburger={useHamburger}
        dropdown={larryDropdown || caraDropdown}
      >
        {useHamburger && larryDropdownEl}
        {useHamburger && caraDropdownEl}
        <CSSTransition
          in={showMainLinks}
          timeout={200}
          mountOnEnter={true}
          unmountOnExit={true}
          appear={true}
        >
          <Ul useHamburger={useHamburger}>
            <ListItem
              darkBg={darkBg}
              current={currentPageTitle === PageTitles.Home}
              useHamburger={useHamburger}
            >
              <NavLink to={PageURLs.Home}>
                <span>{PageTitles.Home}</span>
                <Icon src={home} alt='Home icon' />
              </NavLink>
            </ListItem>

            <ListItem
              darkBg={darkBg}
              current={larryPhotosIsCurrentPage}
              useHamburger={useHamburger}
              onClick={addLarryDropdown}
              onMouseEnter={!useHamburger ? addLarryDropdown : undefined}
              onMouseLeave={!useHamburger ? removeLarryDropdown : undefined}
              onFocus={!useHamburger ? addLarryDropdown : undefined}
              onBlur={!useHamburger ? removeLarryDropdown : undefined}
            >
              <NavLink
                to={PageURLs.PhotosLarry}
                onClick={conditionalLinkCancel}
              >
                <span>{PageTitles.PhotosLarry}</span>
                <Icon src={photo} alt='Photos icon' />
              </NavLink>
              {!useHamburger && larryDropdownEl}
            </ListItem>

            <ListItem
              darkBg={darkBg}
              current={caraPhotosIsCurrentPage}
              useHamburger={useHamburger}
              onClick={addCaraDropdown}
              onMouseEnter={!useHamburger ? addCaraDropdown : undefined}
              onMouseLeave={!useHamburger ? removeCaraDropdown : undefined}
              onFocus={!useHamburger ? addCaraDropdown : undefined}
              onBlur={!useHamburger ? removeCaraDropdown : undefined}
            >
              <NavLink to={PageURLs.PhotosCara} onClick={conditionalLinkCancel}>
                <span>{PageTitles.PhotosCara}</span>
                <Icon src={photo} alt='Photos icon' />
              </NavLink>
              {!useHamburger && caraDropdownEl}
            </ListItem>

            <ListItem
              darkBg={darkBg}
              current={currentPageTitle === PageTitles.Contact}
              useHamburger={useHamburger}
            >
              <NavLink to={PageURLs.Contact}>
                <span>{PageTitles.Contact}</span>
                <Icon src={contactIcon} alt='People icon' />
              </NavLink>
            </ListItem>

            <ListItem
              darkBg={darkBg}
              current={currentPageTitle === PageTitles.About}
              useHamburger={useHamburger}
            >
              <NavLink to={PageURLs.About}>
                <span>{PageTitles.About}</span>
                <Icon src={personIcon} alt='Person icon' />
              </NavLink>
            </ListItem>

            <ListItem
              darkBg={darkBg}
              current={currentPageTitle === PageTitles.Cart}
              useHamburger={useHamburger}
            >
              <NavLink to={PageURLs.Cart}>
                <span>{PageTitles.Cart}</span>
                <CartIconContainer>
                  {cartNumber && <span>{cartNumber}</span>}
                  <Icon src={cartIcon} alt='Cart icon' />
                </CartIconContainer>
              </NavLink>
            </ListItem>
          </Ul>
        </CSSTransition>
      </NavContainer>
    </CSSTransition>
  )
}

function getCartNumber(): string | number | null {
  const cart = getCurrentCart()

  const cartLength = cart && cart.length

  return cartLength && cartLength > 0 && cartLength < 10
    ? cartLength
    : cartLength && cartLength === 0
    ? null
    : cartLength && cartLength >= 10
    ? '9+'
    : null
}
