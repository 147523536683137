import React from 'react'
import { CSSTransition } from 'react-transition-group'

import backArrowIcon from '../../../../../static/svg/chevron-left.svg'
import photo from '../../../../../static/svg/image.svg'

import { ICategory } from '../../../../@types'
import {
  caraPhotosSuffix,
  larryPhotosSuffix,
  PageTitles,
} from '../../../../vars'
import { Icon } from '../../Radios/styles'
import { BackButton, CategoryLink, PhotoCategories } from './styles/Categories'

interface IProps {
  dropdown: boolean
  dropdownRef: React.RefObject<HTMLDivElement> | null
  currentPageTitle: PageTitles | string
  allPageTitle: PageTitles
  allPageURL: string
  photosURLPrefix: string
  catSuffix: typeof larryPhotosSuffix | typeof caraPhotosSuffix
  darkBg: boolean
  useHamburger: boolean
  categories: ICategory[]
  setPhotosIsCurrentPage(newVal: boolean): void
  removeDropdown(): void
}

export function Dropdown({
  categories,
  currentPageTitle,
  darkBg,
  dropdown,
  dropdownRef,
  setPhotosIsCurrentPage,
  allPageTitle,
  allPageURL,
  photosURLPrefix,
  catSuffix,
  removeDropdown,
  useHamburger,
}: IProps) {
  return (
    <CSSTransition
      in={dropdown}
      timeout={200}
      appear={true}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      <PhotoCategories
        ref={dropdownRef}
        useHamburger={useHamburger}
        darkBg={darkBg}
      >
        {useHamburger && (
          <BackButton onClick={removeDropdown}>
            <img src={backArrowIcon} alt='Back arrow icon' /> Back
          </BackButton>
        )}

        <CategoryLink
          darkBg={darkBg}
          to={allPageURL}
          key={allPageURL}
          current={currentPageTitle === allPageTitle}
        >
          <Icon src={photo} alt='Photo icon' />
          <span>All</span>
        </CategoryLink>

        {categories.map(category => {
          const current =
            currentPageTitle === `${category.categoryName}${catSuffix}`

          if (current) {
            setPhotosIsCurrentPage(true)
          }

          return (
            <CategoryLink
              darkBg={darkBg}
              to={photosURLPrefix + category.slug.current}
              key={category.slug.current}
              current={current}
            >
              <Icon
                src={category.icon.asset.url}
                alt={category.categoryName + 'icon'}
              />
              <span>{category.categoryName}</span>
            </CategoryLink>
          )
        })}
      </PhotoCategories>
    </CSSTransition>
  )
}
