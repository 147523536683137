import React from 'react'

import downArrowIcon from '../../../../static/svg/arrow-down.svg'
import rightArrowIcon from '../../../../static/svg/arrow-right-circle.svg'

import { PageURLs } from '../../../vars'
import { GoToPhotosLink, Hero, HeroContent, ScrollDownLink } from './styles'
import { ISiteCopy } from '../../../@types'

type Props = Pick<ISiteCopy, 'mainCTAText' | 'siteHeader' | 'siteDescription'>

export function HeroSection({
  mainCTAText,
  siteDescription,
  siteHeader,
}: Props) {
  return (
    <Hero>
      <HeroContent>
        <h1>{siteHeader}</h1>
        <p>{siteDescription}</p>
        <GoToPhotosLink to={PageURLs.PhotosLarry}>
          {mainCTAText}
          <img src={rightArrowIcon} alt='Arrow icon' />
        </GoToPhotosLink>
      </HeroContent>
      <ScrollDownLink href='#introduction'>
        <img src={downArrowIcon} alt='Down arrow icon' />
        <span>Scroll</span>
      </ScrollDownLink>
    </Hero>
  )
}
