import React from 'react'

import { PageURLs } from '../../../vars'
import { SkewedContainer } from '../SkewedContainer'
import { FooterInner, FooterLink, FooterOuter, PageLinks } from './styles'
import { SocialLinks } from '../../common/SocialLinks'

export function Footer() {
  return (
    <SkewedContainer
      outerComponent={FooterOuter}
      innerComponent={FooterInner}
      clockwise={true}
    >
      <PageLinks>
        <FooterLink to={PageURLs.PhotosLarry}>Photos (by Larry)</FooterLink>
        <FooterLink to={PageURLs.PhotosCara}>Photos (by Cara)</FooterLink>
        <FooterLink to={PageURLs.Contact}>Contact</FooterLink>
        <FooterLink to={PageURLs.About}>About</FooterLink>
      </PageLinks>
      <SocialLinks />
    </SkewedContainer>
  )
}
