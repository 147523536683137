import { IWindowWithStripe } from './@types'

export const email = 'hildermanphoto@gmail.com'

export const storageKey: string = 'cartItems'

export const stripeScriptId: string = 'stripe-js'

export const larryPhotosURLPrefix: string = '/Photos/'
export const caraPhotosURLPrefix: string = '/Photos/cara/'
export const photosGeneralURLLarry = larryPhotosURLPrefix + 'all'
export const photosGeneralURLCara = caraPhotosURLPrefix + 'all'
export const larryPhotosSuffix = ' (by Larry)'
export const caraPhotosSuffix = ' (by Cara)'

export enum Api {
  getTotal = '/.netlify/functions/get-total',
}

export const stripePubKey =
  process.env.NODE_ENV === 'production'
    ? 'pk_live_a4VbDuXGL5wRR71U49SpTLtm00sdLGmtLj'
    : 'pk_test_QHZuX36FP8B9rhhdISSC1A7U00OvHt7yYI'

export enum ErrorMsgs {
  default = 'There was a problem processing your request, please try again.',
  stripe = 'An internal error has occurred, please try reloading the page or contacting me at hildermanphoto@gmail.com.',
  payment = 'We encountered a problem while processing the payment. Please ensure the payment information you provided is correct and try again.',
  email = 'We encountered a problem while processing your email. Please ensure it is correct and try again.',
  order = 'We encountered a problem while processing your order. Please ensure the information you provided is correct and try again.',
  shipping = 'Sorry, we are not currently equipped to ship orders this big. Please break your order into smaller pieces and try again.',
  cart = 'An error has occurred while processing your cart. Please try clearing your cart and reloading the page.',
}

export enum CartUpdates {
  ADD = 'add',
  REMOVE = 'remove',
  QUANTITY = 'quantity',
}

export enum PageURLs {
  Cart = '/Cart',
  Checkout = '/Checkout',
  Contact = '/Contact',
  About = '/About',
  PhotosLarry = '/Photos/all',
  PhotosCara = '/Photos/cara/all',
  PurchaseSuccess = '/PurchaseSuccess',
  Home = '/',
}

export enum PageTitles {
  Cart = 'Cart',
  Checkout = 'Checkout',
  Contact = 'Contact',
  About = 'About',
  PhotosLarry = 'Photos (by Larry)',
  PhotosCara = 'Photos (by Cara)',
  Home = 'Home',
  NotFound = 'Not found',
  PurchaseSuccess = 'PurchaseSuccess',
}

const deviceSizes = {
  tablet: 768,
  desktop: 769,
  handheld: 576,
}

export enum DeviceNames {
  Tablet = 'tablet',
  Handheld = 'handheld',
  Desktop = 'desktop',
}

export const mediaQueries = {
  portrait: {
    handheld: `(max-width: ${deviceSizes.handheld /
      16}em) and (orientation: portrait)`,
    tablet: `(max-width: ${deviceSizes.tablet /
      16}em) and (min-width: ${deviceSizes.handheld /
      16}em) and (orientation: portrait)`,
    desktop: `(min-width: ${deviceSizes.desktop /
      16}em) and (orientation: portrait)`,
  },
  landscape: {
    handheld: `(max-height: ${deviceSizes.handheld /
      16}em) and (orientation: landscape)`,
    tablet: `(max-height: ${deviceSizes.tablet /
      16}em) and (min-height: ${deviceSizes.handheld /
      16}em) and (orientation: landscape)`,
    desktop: `(min-height: ${deviceSizes.desktop /
      16}em) and (orientation: landscape)`,
  },
  general: {
    handheld: `(max-width: ${deviceSizes.handheld / 16}em)`,
    tablet: `(max-width: ${deviceSizes.tablet / 16}em)`,
    desktop: `(min-width: ${deviceSizes.desktop / 16}em)`,
  },
}

export const infoFormLabels: string[] = [
  'Full name',
  'Email',
  'Phone',
  'Address line 1',
  'Address line 2',
  'City',
  'Province',
  'Country',
  'Postal code',
]

export const windowWithStripe: IWindowWithStripe | false = (typeof window !==
  'undefined' && window) as IWindowWithStripe | false
